































import { Component, Vue, Watch } from 'vue-property-decorator'
import { StudentController } from '@/services/request.service'

@Component
export default class Instruction extends Vue {
  private instructionForm: any
  private data: any = {}
  private loading: boolean = false
  private formItemLayout: any = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private beforeCreate(): void {
    this.instructionForm = this.$form.createForm(this)
  }

  @Watch('studentId', { immediate: true })
  private onStudentChange(val): void {
    if (val) {
      this.getData()
    }
  }

  private getData(): void {
    this.loading = true
    this.data = {}
    StudentController.getRemarks(this.studentId)
      .then(res => {
        this.data = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }
}
