






































































































































































































import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { StudentController } from '@/services/request.service'

@Component
export default class Student extends Vue {
  private studentForm: any
  private siblingsForm: any
  private data: any = {}
  private siblingsData: Array<any> = []
  private formLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private loading: boolean = false

  private moment = moment

  private get locale(): string {
    return this.$store.state.locale
  }

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private beforeCreate(): void {
    this.studentForm = this.$form.createForm(this)
    this.siblingsForm = this.$form.createForm(this)
  }

  @Watch('studentId', { immediate: true })
  private onStudentChange(val): void {
    if (val) {
      this.getData()
    }
  }

  private getData(): void {
    this.loading = true
    this.data = {}
    this.siblingsData = []
    StudentController.detail(this.studentId)
      .then(res => {
        const { siblings, ...data } = res.data
        this.data = data
        this.siblingsData = siblings
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }
}
