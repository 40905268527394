























































































import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
import {
  StudentController,
  DropDownController,
  HonorsAndLeaderShipController,
} from '@/services/request.service'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
import { toPage } from '@/utils/utils'
import DetailModal from '@/components/DetailModal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { fileUploader } from '@/services/qiniu.service'
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    DetailModal,
    FlexTooltip,
  },
})
export default class Honor extends Vue {
  private data: any = []
  private detailModalVis: boolean = false
  private filter: any = {
    typeId: 0,
    gradeId: 0,
    honorTime: [],
  }
  private grades: any = []
  private types: any = []
  private loading: any = false
  private honorInfo: any = {}
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'type',
        key: 'type',
        title: this.$t('common.type'),
        scopedSlots: { customRender: 'type' },
        ellipsis: true,
      },
      {
        dataIndex: 'name',
        key: 'name',
        title: this.$t('common.name'),
        scopedSlots: { customRender: 'name' },
        ellipsis: true,
      },
      {
        dataIndex: 'section',
        key: 'grade',
        title: this.$t('common.grade'),
      },
      {
        dataIndex: 'time',
        key: 'honorTime',
        title: this.$t('student.honor.honorTime'),
        scopedSlots: { customRender: 'honorTime' },
      },
      {
        dataIndex: 'creator',
        title: this.$t('common.creator'),
        ellipsis: true,
        scopedSlots: { customRender: 'creator' },
      },
      {
        dataIndex: 'createTime',
        key: 'createTime',
        title: this.$t('common.createTime'),
        scopedSlots: { customRender: 'createTime' },
      },
    ]
  }

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private created(): void {
    this.getDropDownList()
  }

  @Watch('studentId', { immediate: true })
  private onStudentChange(val): void {
    if (val) {
      this.getData()
    }
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    if (!this.studentId || this.loading) return
    const start = this.filter.honorTime[0]
      ? moment(this.filter.honorTime[0])
          .startOf('day')
          .valueOf()
      : undefined
    const end = this.filter.honorTime[1]
      ? moment(this.filter.honorTime[1])
          .endOf('day')
          .valueOf()
      : undefined
    this.loading = true
    this.data = []
    HonorsAndLeaderShipController.getHonors(
      this.studentId,
      page.current,
      page.pageSize,
      this.filter.typeId || undefined,
      this.filter.gradeId || undefined,
      start,
      end
    )
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private getDropDownList(): void {
    Promise.all([
      DropDownController.getSectionList(),
      HonorsAndLeaderShipController.getHonorTypes(),
    ]).then(res => {
      this.grades = res[0].data
      this.types = res[1].data
    })
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private viewDetail(id): void {
    HonorsAndLeaderShipController.getHonor(id).then(res => {
      const { type, section, time, name, description, attachments } = res.data
      this.honorInfo.title = this.$t('student.honor.honorDetail')
      this.honorInfo.infoList = [
        {
          key: 'type',
          label: this.$t('common.type'),
          value: this.locale === 'zh' ? type.name : type.enName || type.name,
        },
        {
          key: 'grade',
          label: this.$t('common.grade'),
          value: (section || {}).name,
        },
        {
          key: 'honorTime',
          label: this.$t('student.honor.honorTime'),
          value: time ? moment(time).format('YYYY.MM.DD') : '',
        },
        {
          key: 'name',
          label: this.$t('common.name'),
          value: name,
        },
        {
          key: 'description',
          label: this.$t('common.description'),
          value: description,
          visRow: 6,
        },
        {
          key: 'attachment',
          label: this.$t('common.attachment'),
          value: '',
          attachments: attachments.map(item => {
            return {
              uid: item.resourceId,
              url: item.resourceUrl,
              name: item.resourceName,
              status: 'done',
            }
          }),
        },
      ]
      this.detailModalVis = true
    })
  }
}
