



































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { DropDownController, GraduationController } from '@/services/request.service'
import cloneDeep from 'lodash/cloneDeep'
import FlexTooltip from '@/components/FlexTooltip.vue'
import StandardViewer from './StandardViewer.vue'

@Component({
  components: {
    FlexTooltip,
    StandardViewer,
  },
})
export default class GraduationProgress extends Vue {
  private loading: Boolean = false
  private data: Array<any> = []
  private subjects: Array<any> = []
  private standard: any = {
    name: '',
    compulsory: 0,
    compulsoryTotal: 0,
    elective: 0,
    electiveTotal: 0,
    graduationSchedule: 0,
  }
  private progress: any = {
    ongoingSchedule: 0,
    completeCourseCredits: 0,
    creditCount: 0,
    graduationSchedule: 0,
    ongoingCourseCredits: 0,
  }
  private standardVis = false

  private get studentId(): any {
    return this.$store.state.currentStudent
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private get compulsory(): Array<any> {
    return this.data.filter(item => item.courseType === 'COMPULSORY')
  }

  private get elective(): Array<any> {
    return this.data.filter(item => item.courseType === 'ELECTIVE')
  }

  private get columns(): any {
    return [
      {
        dataIndex: 'courseName',
        title: this.$t('graduation.course'),
        scopedSlots: { customRender: 'commonFlex' },
        // width: 200,
        ellipsis: true,
      },
      {
        dataIndex: 'subjectName',
        title: this.$t('common.subject'),
        scopedSlots: { customRender: 'course' },
        // ellipsis: true,
        filters: cloneDeep(this.subjects),
        onFilter: (value, record) => record.subjectName.indexOf(value) === 0,
      },
      {
        dataIndex: 'courseCredits',
        title: this.$t('graduation.credit'),
        // width: 100,
      },
      {
        dataIndex: 'remark',
        title: this.$t('graduation.remark'),
        scopedSlots: { customRender: 'remark' },
        ellipsis: true,
      },
    ]
  }

  @Watch('studentId', { immediate: true })
  private onStudentChange(val): void {
    if (val) {
      this.getData()
      this.getFilter()
    }
  }

  private getData(): void {
    this.loading = true
    Promise.all([
      GraduationController.graduationSchedule(this.studentId),
      GraduationController.studentStandard(this.studentId),
    ])
      .then(res => {
        this.data = res[0].data.scsResponses
        this.progress = res[0].data.percentResponse
        this.standard = res[1].data
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private getFilter(): void {
    DropDownController.getSubjectBySectionsUnion({ campusTypes: ['1233'] })
      .then(res => {
        this.subjects = res.data.map(item => {
          return {
            text: item.value,
            value: item.value,
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
  }
}
