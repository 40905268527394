






















































































import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
import { StudentController } from '@/services/request.service'
import { getSchoolInfo } from '@/utils/utils'

@Component
export default class Parent extends Vue {
  private firstParentForm: any
  private secondParentForm: any
  private data: any = {}
  private firstParentData: any = {}
  private secondParentData: any = {}
  private secondParentVisible = false
  private loading = false

  private get locale(): string {
    return this.$store.state.locale
  }

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private beforeCreate(): void {
    this.firstParentForm = this.$form.createForm(this)
    this.secondParentForm = this.$form.createForm(this)
  }

  @Watch('studentId', { immediate: true })
  private onStudentChange(val): void {
    if (val) {
      this.getData()
    }
  }

  private getData(): void {
    this.firstParentData = {}
    this.secondParentData = {}
    this.loading = true
    StudentController.getParents(this.studentId)
      .then(res => {
        this.data = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }
}
