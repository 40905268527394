



























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { StudentController, DropDownController } from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class Subject extends Vue {
  private data: any = []
  private loading: boolean = true

  private filter: any = {
    schoolYearId: undefined,
  }
  private schoolYearList: any = []

  private get locale(): any {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'classType',
        key: 'classType',
        title: this.$t('student.classType'),
        scopedSlots: { customRender: 'classType' },
      },
      {
        dataIndex: 'classTypeName',
        key: 'classTypeName',
        title: this.$t('common.class'),
      },
      {
        key: 'teachers',
        title: this.$t('student.teacher'),
        scopedSlots: { customRender: 'teacher' },
      },
    ]
  }

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  @Watch('studentId', { immediate: true })
  private onStudentChange(val): void {
    if (val) {
      this.getSchoolYearList()
    }
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearRuleList().then(res => {
      this.schoolYearList = res.data
      this.filter.schoolYearId = this.schoolYearList[0].key
      this.getData()
    })
  }

  private getData(): void {
    StudentController.classInfo(this.filter.schoolYearId, this.studentId)
      .then(res => {
        this.data = res.data
        this.loading = false
      })
      .catch(err => {
        console.error(err)
      })
  }

  private copyLink(email): void {
    const input = document.createElement('input')
    document.body.appendChild(input)
    input.setAttribute('readonly', 'readonly')
    input.setAttribute('value', email)
    input.select()
    // ios兼容
    input.setSelectionRange(0, email.length)
    try {
      document.execCommand('copy')
    } catch (err) {
      console.log(err)
    }
    document.body.removeChild(input)
    this.$message.success(this.$tc('common.copySuccess'))
  }
}
