
















































































import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
import {
  StudentController,
  DropDownController,
  HonorsAndLeaderShipController,
} from '@/services/request.service'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
import { toPage, clearEmptyArray } from '@/utils/utils'
import DetailModal from '@/components/DetailModal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { fileUploader } from '@/services/qiniu.service'
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    DetailModal,
    FlexTooltip,
  },
})
export default class Leadership extends Vue {
  private data: any = []
  private detailModalVis: boolean = false
  private editType: any = 'add'
  private filter: any = {
    typeId: [],
    tenure: [],
  }
  private loading: any = false
  private leaderInfo: any = {}
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private types: any = []

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'leaderShipType',
        key: 'type',
        title: this.$t('common.type'),
        scopedSlots: { customRender: 'type' },
        ellipsis: true,
      },
      {
        dataIndex: 'description',
        key: 'description',
        title: this.$t('common.description'),
        scopedSlots: { customRender: 'description' },
        ellipsis: true,
      },
      {
        key: 'tenure',
        title: this.$t('student.leadership.tenure'),
        scopedSlots: { customRender: 'tenure' },
      },
      {
        dataIndex: 'creator',
        title: this.$t('common.creator'),
        ellipsis: true,
        scopedSlots: { customRender: 'creator' },
      },
      {
        dataIndex: 'createTime',
        key: 'createTime',
        title: this.$t('common.createTime'),
        scopedSlots: { customRender: 'createTime' },
      },
    ]
  }

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  @Watch('studentId', { immediate: true })
  private onStudentChange(val): void {
    if (val) {
      this.getData()
    }
  }

  private created(): void {
    this.getDropDownList()
  }

  private filterData(inputValue, path) {
    return path.some(option => option.label.indexOf(inputValue) > -1)
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    if (!this.studentId || this.loading) return
    const start = this.filter.tenure[0]
      ? moment(this.filter.tenure[0])
          .startOf('day')
          .valueOf()
      : undefined
    const end = this.filter.tenure[1]
      ? moment(this.filter.tenure[1])
          .endOf('day')
          .valueOf()
      : undefined
    this.loading = true
    this.data = []
    HonorsAndLeaderShipController.getLeaderShipByStudent(
      this.studentId,
      page.current,
      page.pageSize,
      this.filter.typeId[1],
      start,
      end
    )
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private getDropDownList(): void {
    Promise.all([HonorsAndLeaderShipController.getLeadershipTypes()]).then(res => {
      this.types = clearEmptyArray(res[0].data)
    })
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private viewDetail(id): void {
    HonorsAndLeaderShipController.getLeaderShip(id).then(res => {
      const { type, start, end, description, attachments } = res.data
      this.leaderInfo.title = this.$t('student.leadership.leaderDetail')
      this.leaderInfo.infoList = [
        {
          key: 'type',
          label: this.$t('common.type'),
          value: this.locale === 'zh' ? type.name : type.enName || type.name,
        },
        {
          key: 'tenure',
          label: this.$t('student.leadership.tenure'),
          value: Vue.filter('doubleMoment')([start, end], 'YYYY.MM.DD'),
        },
        {
          key: 'description',
          label: this.$t('common.description'),
          value: description,
          visRow: 6,
        },
        {
          key: 'attachment',
          label: this.$t('common.attachment'),
          value: '',
          attachments: attachments.map(item => {
            return {
              uid: item.resourceId,
              url: item.resourceUrl,
              name: item.resourceName,
              status: 'done',
            }
          }),
        },
      ]
      this.detailModalVis = true
    })
  }
}
