const listers = {}
const $on = (name: string, func: Function) => {
  if (!listers[name]) {
    listers[name] = []
  }
  listers[name].push(func)
}
const $off = (name: string, func: Function) => {
  const list = listers[name] || []
  const index = list.indexOf(func)
  if (index !== -1) {
    list.splice(index, 1)
  }
}
const eventBus = {
  $on,
  $emit: (name: string, ...args: any[]) => {
    listers[name]?.forEach(func => {
      func(...args)
    })
  },
  $listeners: listers,
  $once: (name: string, func: Function) => {
    const func2 = (...args: any[]) => {
      func(...args)
      $off(name, func2)
    }
    $on(name, func2)
  },
  $off,
}

export default eventBus
