








































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { StudentController } from '@/services/request.service'
import { getSchoolInfo, isBase64, setCurrentStudent } from '@/utils/utils'
import { fileUploader } from '@/services/qiniu.service'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { differSchoolId } from '@/constant/config'

import Student from './StudentDetail/Student.vue'
import Parent from './StudentDetail/Parent.vue'
import Curriculum from './StudentDetail/Curriculum.vue'
// import Teacher from './StudentDetail/Teacher.vue'
import Subject from './StudentDetail/Subject.vue'
import Class from './StudentDetail/Class.vue'
import gradeBook from './StudentDetail/GradeBook.vue'
import Report from '../Report/components/Report.vue'
import Points from './StudentDetail/Points.vue'
import Instruction from './StudentDetail/Instruction.vue'
import cloneDeep from 'lodash/cloneDeep'
import KSGrading from './StudentDetail/KSGrading.vue'
import Honor from './StudentDetail/Honor.vue'
import Leadership from './StudentDetail/Leadership.vue'
import GraduationProgress from './StudentDetail/GraduationProgress.vue'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    Student,
    Parent,
    Curriculum,
    // Teacher,
    Subject,
    Class,
    gradeBook,
    Report,
    Instruction,
    Points,
    KSGrading,
    Honor,
    Leadership,
    GraduationProgress,
    VueCropper,
  },
})
export default class StudentDetail extends Vue {
  private newAvatar = ''
  private avatarVisible = false
  private menuKey = ['student']
  private saveLoading = false
  private canLeave = true
  private info: any = {}
  private schoolId: any = getSchoolInfo().schoolId
  private differSchoolId = differSchoolId

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private set studentId(newVal) {
    const student = this.students.filter(student => student.studentId === newVal)[0]
    this.$store.commit('setCurrentStudent', student)
    setCurrentStudent(newVal)
  }

  private get students(): any {
    return this.$store.state.students
  }

  @Watch('studentId', { immediate: true })
  private onStudentChange(val): void {
    if (val) {
      this.getStudentBrief()
    }
  }

  private getStudentBrief(): void {
    StudentController.getStudentBriefInfo(this.studentId)
      .then(res => {
        this.info = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private onStatusChange(val): void {
    if (val === 'edit') {
      this.canLeave = false
    } else if (val === 'view') {
      this.canLeave = true
    }
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (!this.canLeave) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private changeMenu({ item, key, keyPath }): void {
    if (this.menuKey[0] !== key) {
      if (this.canLeave) {
        this.menuKey = [key]
      } else {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            this.menuKey = [key]
            this.canLeave = true
          },
        })
      }
    }
  }

  private sendMessage(): void {
    const studentId = parseInt(this.$route.params.studentId, 10)
    this.$store.commit('setMessageStudents', [studentId])
    this.$router.push({ name: 'sendMessage' })
  }

  public cropAvatar(): void {
    const cropper = this.$refs.cropper as any
    this.newAvatar = cropper.getCroppedCanvas().toDataURL()
  }

  public saveAvatar(): void {
    this.saveLoading = true
    const avatar = this.newAvatar
    let base = avatar.split(',')
    if (isBase64(base[1])) {
      const avatarFile = this.blobToFile(
        this.dataURLtoBlob(avatar),
        'avatar_' + new Date().getTime() + '.png'
      )
      const index = avatarFile.name.lastIndexOf('.')
      const suffix = avatarFile.name.slice(index)
      const fileName = 'avatar_' + new Date().getTime() + suffix
      fileUploader(avatarFile, fileName)
        .then(res => {
          const avatarUrl = process.env.VUE_APP_FILE_URL + res.key
          StudentController.updateAvatarUrl({
            avatarUrl: avatarUrl,
            studentId: this.studentId,
          })
            .then(res => {
              this.$set(this.info, 'avatarUrl', avatarUrl)
              this.$message.success(this.$tc('common.saveSuccess'))
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              this.saveLoading = false
              this.avatarVisible = false
            })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.saveLoading = false
          this.avatarVisible = false
        })
    } else {
      this.saveLoading = false
      this.avatarVisible = false
    }
    this.$route.meta.refresh = true
  }

  private uploadAvatar(): void {
    this.avatarVisible = true
    this.newAvatar = cloneDeep(this.info.avatarUrl)
  }

  public setImage(e): void {
    const file = e.target.files[0]
    if (!file.type.includes('image/')) {
      this.$message.error(this.$tc('student.wrongAvatar'))
      return
    }
    if (typeof FileReader === 'function') {
      const reader = new FileReader()

      reader.onload = event => {
        const target = event.target as any
        this.newAvatar = target.result
        const cropper = this.$refs.cropper as any
        cropper.replace(target.result)
      }

      reader.readAsDataURL(file)
    } else {
      this.$message.error(this.$tc('student.wrongBrowser'))
    }
  }

  public blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date()
    theBlob.name = fileName
    return theBlob
  }

  public dataURLtoBlob(dataurl): Blob {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }
}
